<!-- Button trigger modal -->
<template>
  <div>
    <div
      style="padding-right: 0 !important"
      class="modal fade"
      :class="[route === 'Sure' ? 'top-push' : '']"
      id="sub"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div
        style="margin-bottom: 6rem;"
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header" style="border-bottom: 0.5px solid #e2e8f0">
            <!-- <h5 class="modal-title" id="sub">Modal title</h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="font-weight: normal">X</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <img src="../assets/subscription/handshake-subscription.svg" alt="" />
            </div>
            <div class="top_header">
              <h4>Shelta Value Added Services</h4>
              <p>
                For just <span style="font-weight: bold;">₦20,000</span>, you can gain access to a whole new world of amazing rewards, incentives and enhanced offerings with Shelta's Smart Associate
              </p>
            </div>
            <div>
              <p class="benefits-text">Benefits</p>
              <h6 class="mouth-watering">Mouth watering Commissions and Incentives when you recruit Shelta and Smart Users</h6>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Rent Finance
                    </h6>
                    <!-- <p>
                      Enjoy flexibility as Shelta enables you switch your annual
                      payment to start paying rent monthly, quarterly and
                      biannually to us while your landlord gets full annual rent
                      paid to them by Shelta. Eligibility requirement applies.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Zero Agency Fee and Zero Legal Fees:
                    </h6>
                    <!-- <p>
                      Enjoy Zero Agency and Zero Legal fees as Shelta does not
                      require you to pay agency fee or legal/agreement fee when
                      moving into a new apartment managed by Shelta anywhere in
                      Nigeria.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Property Swapping
                    </h6>
                    <!-- <p>
                      As a Shelta user, you can Swap your apartment for another
                      within and outside the Shelta ecosystem - T&C applies!
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Rent Gap Financing
                    </h6>
                    <!-- <p>
                      Shelta helps you complete your rent when the need arises.
                      This service is made possible through Shelta SAFE.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Property Financing
                    </h6>
                    <!-- <p>
                      We can help finance your property acquisition project
                      including mortgage facilitation.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Tenant/Landlord Advisory Service
                    </h6>
                    <!-- <p>
                      Tenant and landlord advisory service on all property
                      related issues - A personal caretaker service.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Guaranteed Rent for Landlords
                    </h6>
                    <!-- <p>
                      Tenant and landlord advisory service on all property
                      related issues - A personal caretaker service.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >Property Management
                    </h6>
                    <!-- <p>
                      Tenant and landlord advisory service on all property
                      related issues - A personal caretaker service.
                    </p> -->
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/subscription/check-circle-blue.svg"
                          alt=""/></span
                      >And more...
                    </h6>
                    <!-- <p>
                      Tenant and landlord advisory service on all property
                      related issues - A personal caretaker service.
                    </p> -->
                  </div>
                </div>
              </div>

              <div class="note">
                <h6>Note</h6>
                <ul>
                  <li>
                    This subscription is non-refundable. 
                  </li>
                  <li>
                    Please note that subscription alone does not guarantee eligibility for rent financing. T&C applies.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer modal-button main-footer-button">
            <!-- <button type="button" class="btn cancel" data-dismiss="modal">
                Cancel
              </button> -->
            <button class="btn continue" data-toggle="modal" data-target="#paymentOption" @click="closeMainModal">SUBSCRIBE NOW <i class="fa fa-arrow-right"></i></button>
            <!-- <paystack
              type="button"
              class="btn continue"
              :amount="amount * 100"
              :email="email"
              :paystackkey="PUBLIC_KEY"
              :reference="reference"
              :callback="processPayment"
              :close="close"
              :channels="channels"
              @click="$refs.paystack.click()"
            >
              SUBSCRIBE NOW
              <i class="fa fa-arrow-right"></i>
            </paystack> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for benefits end -->

    <!-- Modal for amount confirmation begins -->
    <div
      tabindex="-1"
      class="modal fade"
      id="paymentConfirm"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header">
            <h5 class="modal-title confirmation-header">
              Subscription Confirmation
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="font-weight: normal">X</span>
            </button>
          </div>
          <div class="modal-body confirmation-body">
            <p>Great choice!</p>
            <p>
              You are now on your way to becoming a valued Shelta member. A
              payment of <span>₦20,000</span> will be deducted from your
              account to activate your Shelta membership.
            </p>
          </div>
          <div class="modal-footer modal-button">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn continue"
              @click="closeConfirmation"
              data-toggle="modal"
              data-target="#paymentOption"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for amount confirmation ends -->

    <!-- Modal for payment starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="paymentOption"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header">
            <h5 class="modal-title confirmation-header">
              Payment Method
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                @click="paymentType = ''"
                aria-hidden="true"
                style="font-weight: normal"
                >X</span
              >
            </button>
          </div>
          <div class="modal-body">
            <div class="options">
              <button
                class="btn d-flex justify-content-between center"
                type="button"
                :disabled="subscribed === 'YES'"
                @click="
                  $refs.voucher.click();
                  scrollView();
                "
              >
                
                <div>
                  <img
                    style="width: 24px; margin-left: 10px;"
                    src="../assets/subscription/blue-safe.svg"
                    alt="voucher"
                  />
                  <span class="pl-2">VOUCHER</span>
                </div>

                <div>
                    <input
                    ref="voucher"
                    v-model="paymentType"
                    value="voucher"
                    style="width: 20px; height: 20px;"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                </div>
              </button>
              <button
                class="btn d-none justify-content-between center"
                type="button"
                @click="
                  $refs.safe.click();
                  scrollView();
                "
              >
                
                <div>
                  <img
                    style="width: 24px; margin-left: 10px;"
                    src="../assets/subscription/blue-safe.svg"
                    alt="safe"
                  />
                  <span class="pl-2">SAFE</span>
                </div>

                <div>
                  <input
                    ref="safe"
                    v-model="paymentType"
                    value="safe"
                    style="width: 20px; height: 20px;"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                </div>
              </button>
              <paystack
                :disabled="subscribed === 'YES'"
                :channels="channels"
                :amount="amount * 100"
                :email="email"
                :paystackkey="PUBLIC_KEY"
                :reference="reference"
                :callback="processPayment"
                :close="close"
                class="btn d-flex justify-content-between"
                type="button"
                @click="$refs.paystack.click()"
              >
                
                <div>
                  <img
                    style="width: 24px; margin-left: 10px;"
                    src="../assets/subscription/paystack-blue.svg"
                    alt="paystack"
                  /><span class="pl-2">Paystack</span>
                </div>


                <div>
                  <input
                    v-model="paymentType"
                    value="paystack"
                    ref="paystack"
                    style="width: 20px; height: 20px;"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                </div>
              </paystack>
              <!-- <button
                class="btn"
                type="button"
                @click="
                  $refs.bank.click();
                  payFromBank();
                "
              >
                <input
                  v-model="paymentType"
                  value="bank"
                  ref="bank"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                /><img
                  style="margin-left: 10px;"
                  src="../assets/subscription/bank-transfer.svg"
                  alt="transfer"
                /><span class="pl-2">Bank Transfer</span>
              </button> -->
              <button
                class="btn d-none"
                type="button"
                @click="$refs.naira.click()"
              >
                <input
                  v-model="paymentType"
                  value="naira"
                  ref="naira"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                /><img
                  style="width: 24px; margin-left: 10px;"
                  src="../assets/subscription/enaira-green.svg"
                  alt="enaira"
                /><span class="pl-2">E-Naira</span>
              </button>
            </div>
          </div>
          <div class="modal-footer modal-button">
            <button
              @click="paymentType = ''"
              type="button"
              class="btn cancel"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              id="continueBtn"
              ref="continue"
              v-if="paymentType === 'safe' || paymentType == 'voucher'"
              type="button"
              class="btn continue"
              @click="continueWith()"
            >
              Continue
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loader1"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for payment ends -->

    <!-- Modal to enter voucher starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="voucherModal"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header">
            <h5 class="modal-title confirmation-header">
              Pay with voucher
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                @click="paymentType = ''"
                aria-hidden="true"
                style="font-weight: normal"
                >X</span
              >
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input @input="formatInputRemoveCharacters" maxlength="19" :value="formattedInput" class="form-control input-text" type="text" name="vocher" id="voucher" placeholder="Enter voucher numbers">
            </div>
          </div>
          <div class="modal-footer modal-button">
            <button
              @click="voucherNumber = ''"
              type="button"
              class="btn cancel voucherCancel"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              :disabled="voucherLoading"
              ref=""
              type="button"
              class="btn continue voucherPay"
              @click="subscribedWithVoucher()"
            >
              Pay
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="voucherLoading"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to enter voucher ends -->

  </div>
</template>

<script>
import { Service } from "../store/service";
import paystack from "vue-paystack";
import paymentSevice from "../services/payment";
import userCredentials from "../services/userCredentials";
import { staticdata } from "../data";
const Api = new Service();
import $ from "jquery";
export default {
  name: "SubscriptionModal",
  components: {
    paystack,
    // FooterMain,
  },
  data() {
    return {
      hideDiv: false,
      loading: false,
      voucherLoading: false,
      amount: 20000,
      full_name: "",
      email: "",
      PUBLIC_KEY: staticdata.paystackkeylive,
      user: "",
      reference: "",
      subscribed: "",
      uploading: false,
      loader1: false,
      paymentType: "",
      voucherNumber: "",
      channels: ["card", "bank", "ussd", "qr", "bank_transfer"],
      route: "",
    };
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  created() {
    this.$root.$refs.D = this;
  },
  computed: {
    formattedInput() {
      return this.formatWithHyphens(this.voucherNumber);
    },
  },
  mounted() {
    // location.reload()
    if (localStorage.getItem("shelterUser")) {
      this.user = JSON.parse(localStorage.getItem("shelterUser")).user;
      this.email = this.user.email;
      this.full_name = this.user.firstname + this.user.lastname;
      this.getSubscription();
    }
    this.route = this.$route.name;
  },
  methods: {
    formatInputRemoveCharacters(event) {
      let value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric values
      value = value.slice(0, 16); // Limit to 16 digits (without hyphens)
      this.voucherNumber = value; // Update the raw input
    },
    formatWithHyphens(value) {
      return value.replace(/(\d{4})(?=\d)/g, "$1-"); // Add hyphen after every 4 digits except last
    },
    openSubModal() {
      $("#sub")
        .addClass("fade")
        .modal("show");
    },
    closeMainModal() {
      $("#sub")
        .removeClass("fade")
        .modal("hide");
    },
    // openVouchModal() {
    //   $("#paymentConfirm")
    //     .removeClass("fade")
    //     .modal("hide");
    //   $("#voucherModal")
    //     .addClass("fade")
    //     .modal("show");
    // },
    closeConfirmation() {
      $("#paymentConfirm")
        .removeClass("fade")
        .modal("hide");
    },
    processPayment(response) {
      const ref = response.reference;
      if (response.status === "success") {
        let body = {
                amount: 20000,
                referencecode: ref,
            }
        body = JSON.stringify(body);
        paymentSevice.payment(body)
          .then((res) => {
            // console.log("Subscription response", res);
            if (res.success === "success") {
              location.reload();
              this.getSubscription();
              $("#sub")
                .removeClass("fade")
                .modal("hide");
                this.$toast.success("Subscription successful");
            }
            if (res.error) {
                this.$toast.error(res.error);
                $("#sub")
                .removeClass("fade")
                .modal("hide");
            }
          })
          .catch((err) => {
            console.log("err", err);
            // this.$toast.error(err);
            // return this.$store.commit("setApiFailed", "Error occured");
          });
      }
    },
    // Paystack close function or cancel payment
    close: () => {
      // console.log("You closed checkout page");
    },
    async getSubscription() {
      if(this.user.id != "" || this.user != null) {
        this.hideDiv = true;
        try {
          await userCredentials.userSubscribed(this.user.id)
            .then((res) => {
              this.hideDiv = false;
              // console.log("Subscribed check", res);
              if (res.success === "success") {
              //  this.getUserFullDetails();
                this.$store.dispatch("saveSubscription", res.paid);
                this.subscribed = res.paid;
              }
              // return this.$store.commit("setApiSuccess", "Sub success");
            })
            .catch((err) => {
              console.log("err", err);
              this.hideDiv = false;
              // return this.$store.commit("setApiFailed", "Error occured");
              return this.$toast.error('Error occured');
              
            });
        } finally {
          // console.log("closed");
        }
      }
    },
    payFromSafe() {
      if (this.subscribed === "YES") {
        return this.$toast.error('You already subscribed');
        // return this.$store.commit("setApiFailed", "You already scubscribed");
      } else {
        $("#paymentSafe")
          .addClass("fade")
          .modal("show");
      }
    },
    scrollView() {
      // this.$refs.continue.scrollIntoView();

      this.$nextTick(() => {
      const element = document.getElementById("continueBtn");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
    },
    subscribeWithSafe() {
      this.loader1 = true;
      if (this.subscribed === "YES") {
        this.loader1 = false;
        // return this.$store.commit("setApiFailed", "You already scubscribed");
        return this.$toast.error('You already scubscribed');
      }
      Api.postRequest(`subscriptionwithsafe`, {}).then((res) => {
        console.log("res", res);
        this.loader1 = false;
        if (res.data.success) {
          $("#paymentOption")
            .removeClass("fade")
            .modal("hide");
          // return this.$store.commit("setApiSuccess", "Success");
          return this.$toast.success("Success");
        }
        if (res.data.error) {
          // return this.$store.commit("setApiFailed", res.data.error);
          return this.$toast.error(res.data.error);
        }
      }).catch((err) => {
          console.log(err);
          this.loader1 = false;
      });
      //   .catch(err => {
      //     this.loader1 = false;
      //   });
    },
    continueWith() {
      if (this.paymentType == 'voucher') {
        $("#paymentOption") 
        .removeClass("fade")
        .modal("hide");
        $("#voucherModal")
          .addClass("fade")
          .modal("show");
      }
      if (this.paymentType == 'safe') {
        this.subscribeWithSafe();
      }
    },
    subscribedWithVoucher() {
      this.voucherLoading = true;
      if (this.subscribed === "YES") {
        this.voucherLoading = false;
        return this.$toast.error("You already scubscribed");
      }
      if (this.voucherNumber === "") {
        this.voucherLoading = false;
        return this.$toast.error("Enter voucher numbers");
      }
      let body = {
        amount: this.amount,
        voucher: this.voucherNumber
      }
      paymentSevice.paymentWithVoucherCard(JSON.stringify(body)).then(res => {
  
        console.log("res", res);
        this.voucherLoading = false;
        if (res.success) {
          $("#voucherModal")
            .removeClass("fade")
            .modal("hide");
          this.$toast.success("Subscription successful");
          // return this.$store.commit("setApiSuccess", "Subscription successful");
        }
        if (res.error) {
          return this.$toast.error(res.error);
        }
      }).catch((err) => {
          console.log(err);
          this.voucherLoading = false;
      });
    },
    makePayment() {
      console.log("Payment");
    },
  },
  // Bank transfer ends
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
$shelta-black: #000a2f;
$shelta-gray: #555c74;
$shelta-brown: #553b00;
$shelta-warning: #fff7e6;
$shelta-off-white: #f9fafb;
$shelta-grey-2: #57595a;
$dline: #dddddd;

//   @font-face {
//     font-family: "Gotham-bold";
//     src: local("Gotham-bold"),
//       url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
//   }

//   @font-face {
//     font-family: "Gotham";
//     src: local("Gotham"),
//       url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
//   }

//   @font-face {
//     font-family: "Gotham-medium";
//     src: local("Gotham-medium"),
//       url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
//   }

.top-push {
  margin-top: 7rem;
  // margin-bottom: 5rem;
}

.top_header {
  padding: 24px 0px 16px;
  h4 {
    font-family: "Gotham-Book";
    color: $shelta-black;
    font-weight: bold;
    font-style: normal;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
  }

  p {
    font-family: "Gotham-Book";
    color: $shelta-gray;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
  }
}

.benefits-text {
  text-align: left;
  color: $shelta-black;
  font-family: "Gotham-Book";
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.2px;
  margin-bottom: 10px !important;
}
.mouth-watering {
  color: $shelta-black;
  text-align: left;
  font-family: "Gotham-Book";
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.benefit-wraps {
  text-align: left;

  h6 {
    color: $shelta-black;
    text-align: left;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;
    display: flex;
    margin-bottom: 20px;
    span {
      padding-right: 10px;
    }
  }

  p {
    padding-left: 30px;
    color: $shelta-gray;
    font-family: "Gotham-Book";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 20px !important;
  }
}

.note {
  text-align: left;
  margin-top: 20px;
  padding: 24px 16px;
  background: $shelta-warning;

  h6 {
    color: $shelta-brown;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  ul {
    padding-left: inherit;
  }

  li {
    color: $shelta-brown;
    font-family: "Gotham-Book";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 16px;
  }

  li:last-child {
    padding-bottom: 0;
  }
}

.main-footer-button {
  justify-content: space-around !important;
}
.modal-button {
  // padding-top: 24px;
  // padding-bottom: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-top: none !important;

  .cancel {
    border-radius: 6px;
    border: 1px solid $dline;
    color: $shelta-black;
    width: 178px;
    height: 48px;
    text-align: center;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  .continue {
    border-radius: 6px;
    background: $primary;
    color: $secondary;
    // width: 178px;
    min-height: 48px;
    text-align: center;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    outline: 0;
    box-shadow: none;
  }
}

.confirmation-header {
  color: $shelta-black;
  text-align: center;
  font-family: "Gotham-Book";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.confirmation-body {
  p {
    text-align: left;
    color: $shelta-black;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
  }
}

// New payment design start
.options {
  button {
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    background: #f4f7f9;
    color: $shelta-grey-2;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 16px;
    padding-left: 5px;
    outline: 0;
    box-shadow: none;
  }
}

// New payment design ends

.input-text {
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  box-shadow: none;
  outline: 0;
  color: #555C74;
  height: 60px;

}

.voucherCancel, .voucherPay {
  width: 45% !important;
}

@media only screen and (max-width: 599px) {
  .modal-button {
    display: flex;
    width: auto;
    flex-wrap: nowrap;

    button {
      width: fit-content;
    }
  }

  .top_header {
    h4 {
      font-size: 19px;
    }
  }
}
</style>
